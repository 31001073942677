import React, { useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import InnerLayout from "../../layouts/InnerLayout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useEventsContext from "../../appSettings/customhooks/eventhook.js";
import { IMAGE_EVENTS_URL, images } from "../../appSettings/imageConstants.js";

const EventRegistration = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { events, loading, error } = useEventsContext();

  // Default state for the form
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    occupation: "",
    attending: "",
    gender: "",
    event_id: "",
  });

  // Loading and error states for events data
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Find the event using the slug
  const item = events?.find((event) => event.slug === slug);
  if (!item) return <p>Event not found!</p>;

  // Set the event_id for the hidden field
  const bannerImage = item.event_banner
    ? `${IMAGE_EVENTS_URL}${item.event_banner}`
    : images.InnerBanner;

  // Update formData when user types in fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Append event_id dynamically
      const dataToSubmit = { ...formData, event_id: item.id };
      console.log("Submitting Data:", dataToSubmit);

      const response = await fetch(
        "https://test.kalamlabs.com/api/submit-event-registration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSubmit),
        }
      );

      // Handle response
      if (response.ok) {
        const result = await response.json();
        navigate('/event-registration-success');
        console.log("Server Response:", result);

        // Reset the form
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          occupation: "",
          attending: "",
          gender: "",
          event_id: "",
        });
      } else {
        const errorData = await response.json();
        alert(`Registration failed: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <InnerLayout>
      <Container maxWidth="xl" sx={{ marginY: 10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Event Registration Form
        </Typography>
        <Grid container spacing={4}>
          {/* Form Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Enter Your Details
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Last Name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Phone No"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Occupation"
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          Select Gender
                        </MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>Are You Attending?</FormLabel>
                        <RadioGroup
                          row
                          name="attending"
                          value={formData.attending}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Hidden field for event_id */}
                  <input
                    type="hidden"
                    name="event_id"
                    value={item.id}
                    readOnly
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Register
                  </Button>
        </form>
              </CardContent>
            </Card>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <img
           src={bannerImage}
        alt="Event Illustration"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </CardContent>
          </Card>
          </Grid>
        </Grid>
      </Container>
    </InnerLayout>
  );
};

export default EventRegistration;
