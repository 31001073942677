import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RootProvider from "./appSettings/roots/rootProvider.js";
import routes from "./appSettings/roots/routs.js";
import useTemplatesettings from "../src/appSettings/customhooks/templateSettingshook.js";
import ProtectedRoute from "../src/appSettings/roots/ProtectedRoute.js"; // Path to the ProtectedRoute component

function App() {
  const { templatesettings, loading: settingsLoading } = useTemplatesettings();
  const siteName = templatesettings.find((item) => item.slug === "site-name")?.value || "";

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true"); // Store in localStorage
  };

  // Check if the user is authenticated on page load
  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated");
    setIsAuthenticated(authStatus === "true");
  }, []);

  useEffect(() => {
    if (!settingsLoading && templatesettings) {
      const darkThemeSetting = templatesettings.find((item) => item.slug === "dark-theme");
      if (darkThemeSetting?.value === "1") {
        document.body.classList.add("darktheme");
      } else {
        document.body.classList.remove("darktheme");
      }
    }
  }, [templatesettings, settingsLoading]);

  return (
    <RootProvider>
      <Helmet>
        <title>{siteName}</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            // For ProtectedRoute (like choosephoto)
            if (route.path === "/choosephoto") {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      element={route.element}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
              );
            }

            // For Login route
            if (route.path === "/user-login") {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={React.cloneElement(route.element, { onLogin: handleLogin })}
                />
              );
            }

            return <Route key={index} path={route.path} element={route.element} />;
          })}
        </Routes>
      </BrowserRouter>
    </RootProvider>
  );
}

export default App;
