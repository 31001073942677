import React from "react";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../components/innerBanner/innerBanner";
import {
  Box,
  Container,
  Card,
  CardMedia,
  CardActions,
  Button,
  Typography,
  CardContent,
  Tabs,
  Tab,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import InnerBannerImage from "../../images/inner-banner.png";
import { IMAGE_EVENTS_URL, images } from "../../appSettings/imageConstants.js";
import { useNavigate } from "react-router-dom";
import useEventsContext from "../../appSettings/customhooks/eventhook.js"; // Import useEvents hook directly
import { CalendarMonth } from "@mui/icons-material";
import { useState } from "react";


// Utility to format date-time
const formatDateTime = (dateTime) => {
  if (!dateTime) return null;

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateTime));
};

function Events() {
  const navigate = useNavigate();
  const { events, loading, error } = useEventsContext();
  const [tabVal, setTabVal] = useState(0);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const publishedEvents = Array.isArray(events) ? events.filter((event) => event.publish === 1) : [];

  // filter events based on date
  const current = new Date();
  const allEvents = publishedEvents;
  const ongoingEvents = publishedEvents.filter(
    (event) => 
      new Date(event.start_date_and_time) <= current
    &&
    new Date(event.end_date_and_time) >= current
  );
  const upcomingEvents = publishedEvents.filter(
    (event) => 
      new Date(event.start_date_and_time) > current
  );
  const pastEvents = publishedEvents.filter(
    (event) => 
      new Date(event.end_date_and_time) < current
  );

  let displayEvents;
  if(tabVal === 0){
    displayEvents = allEvents;
  } else if(tabVal === 1){
    displayEvents = ongoingEvents;
  } else if(tabVal === 2){
    displayEvents = upcomingEvents;
  } else{
    displayEvents = pastEvents;
  }

  const handleTabChange = (event, newVal) => {
    setTabVal(newVal);
  };

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title="Events"
        tagline="Come and Join our Events"
      />
      <Box>
        <Box
          sx={{ padding: "1rem 0" }}>           
          <Tabs
              value={tabVal}
              onChange={handleTabChange}
              aria-label="Event Categories"
              centered
            >
              <Tab label="All" />
              <Tab label="Ongoing Events" />
              <Tab label="Upcoming Events" />
              <Tab label="Past Events" />
          </Tabs>
        </Box>

      <Box component="section" sx={{ padding: "2rem 0" }}>
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            {displayEvents.length > 0 ? (
              displayEvents.map((item) => (
                <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={item.id}>
                  <Card className="event-card" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <CardMedia
                      component="img"
                      sx={{ height: 240 }}
                      src={item.event_banner ? `${IMAGE_EVENTS_URL}${item.event_banner}` : images.NoImage}
                      title={item.name || "Event Image"}
                    />
                    {item.type && <Typography className="postion-text">{item.type} Event</Typography>}
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.name}
                      </Typography>
                      {(item.start_date_and_time || item.end_date_and_time) && (
                        <Box className="dateBlock" sx={{ display: "flex", width: "100%", mb: 2 }}>
                          {item.start_date_and_time && (
                            <Typography className="mute-text">
                              <CalendarMonth /> {formatDateTime(item.start_date_and_time)}
                            </Typography>
                          )}
                          {item.end_date_and_time && (
                            <Typography className="mute-text">-to - {formatDateTime(item.end_date_and_time)}</Typography>
                          )}
                        </Box>
                      )}
                      <Typography variant="body2" color="textSecondary">
                        {item.short_description && (
                          <Box component="div" dangerouslySetInnerHTML={{ __html: item.short_description }} />
                        )}
                      </Typography>
                    </CardContent>
                    {(item.registration_button === 1 || item.long_description) && (
                      <CardActions sx={{ mt: "auto", px: 3, pb: 3 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          {item.long_description && (
                            <Button size="small" variant="contained" onClick={() => navigate(`/events/${item.slug}`)}>
                              Read More
                            </Button>
                          )}
                          {item.registration_button === 1 && (
                            <Button size="small" variant="contained" color="secondary" onClick={() => navigate(`/event-registration/${item.slug}`)}>
                              Register
                            </Button>
                          )}
                        </Box>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))
            ) : (
              <div>No events found.</div>
            )}
          </Grid>
        </Container>
      </Box>
      </Box>
    </InnerLayout>
  );
}

export default Events;
