import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InnerLayout from "../layouts/InnerLayout";
import { Box, Container } from "@mui/system";
import { Button } from "@mui/material";

function Choosephoto() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Set to null initially
  const navigate = useNavigate();

  // Check URL on initial page load and redirect if necessary
  useEffect(() => {
    const currentUrl = window.location.pathname;

    // If the URL is not '/choosephoto', redirect to '/choosephoto'
    if (currentUrl !== "/choosephoto") {
      navigate("/choosephoto");
    }
  }, [navigate]);

  // Retrieve the authentication status from localStorage
  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated");
    
    if (authStatus === "true") {
      setIsAuthenticated(true); // User is authenticated
    } else {
      setIsAuthenticated(false); // User is not authenticated
    }
  }, []); // Empty dependency array ensures this effect runs once on mount

  // Handle logout
  const handleLogout = () => {
    // Clear session and local storage
    localStorage.removeItem("isAuthenticated");
    sessionStorage.clear();

    // Set authentication state to false
    setIsAuthenticated(false);

    // Redirect to login page
    navigate("/user-login");
  };

  // While authentication is being checked, show loading state
  if (isAuthenticated === null) {
    return <p>Loading...</p>;
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    navigate("/user-login");
    return null; // Return nothing as we're redirecting
  }

  // If authenticated, render the Choosephoto page
  return (
    <InnerLayout>
      <Box component="section">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogout}
          sx={{ mb: 2 }}
        >
          Log Out
        </Button>
        <iframe
          src="https://f9tech.com/" // Replace with your desired URL
          title="Embedded Web Page"
          style={{ width: "100%", height: "80vh", border: "none" }}
        ></iframe>
      </Box>
    </InnerLayout>
  );
}

export default Choosephoto;
